import React from 'react'
import Link from 'gatsby-link'

const Footer = () => (
  <footer id="page-footer">
    <div className="ruddjoinery-logo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.14 110.41">
        <path d="M35.55,103.7c0,3.36-1.8,5.08-4.64,5.08s-4.71-1.87-4.71-5.19V94.74H24.46v9c0,4.35,2.6,6.69,6.41,6.69s6.42-2.34,6.42-6.82V94.74H35.55Zm88.35,1.36c0,2.44-1.27,3.7-3,3.7s-2.57-.77-3.52-2.2l-1.26,1.08a5.29,5.29,0,0,0,4.76,2.72,4.76,4.76,0,0,0,3.39-1.27,5.57,5.57,0,0,0,1.4-4V94.74H123.9ZM81.55,94.74H76.21v15.4h5.34c4.84,0,8.19-3.36,8.19-7.7v0C89.74,98.07,86.39,94.74,81.55,94.74Zm6.38,7.75c0,3.41-2.48,6.05-6.38,6.05H78V96.35h3.6a6,6,0,0,1,6.38,6.09ZM55.55,94.74H50.2v15.4h5.35c4.84,0,8.18-3.36,8.18-7.7v0C63.73,98.07,60.39,94.74,55.55,94.74Zm6.38,7.75c0,3.41-2.49,6.05-6.38,6.05H51.94V96.35h3.61a6,6,0,0,1,6.38,6.09ZM12.19,99.36v0a4.26,4.26,0,0,0-1.19-3,6.07,6.07,0,0,0-4.38-1.54H0v15.4H1.74v-6h4.4l4.47,6h2.13L8,103.85C10.43,103.41,12.19,101.92,12.19,99.36ZM1.74,102.6V96.35H6.49c2.49,0,3.94,1.14,3.94,3v.05c0,2-1.65,3.17-4,3.17Zm190.87,4.49-9.7-12.35h-1.63v15.4H183V97.51l9.95,12.63h1.38V94.74h-1.69ZM134.07,0A35.44,35.44,0,1,0,169.5,35.43,35.44,35.44,0,0,0,134.07,0Zm0,68.24a32.81,32.81,0,1,1,32.81-32.81A32.81,32.81,0,0,1,134.07,68.24Zm75.13,34.93h8.4v-1.58h-8.4V96.33h9.39V94.74H207.46v15.4H218.7v-1.58h-9.5Zm34-3.81v0a4.26,4.26,0,0,0-1.19-3,6.07,6.07,0,0,0-4.38-1.54H231v15.4h1.74v-6h4.4l4.46,6h2.14L239,103.85C241.43,103.41,243.19,101.92,243.19,99.36Zm-10.45,3.24V96.35h4.75c2.48,0,3.94,1.14,3.94,3v.05c0,2-1.65,3.17-4,3.17Zm33.37-7.86-5.24,7.7-5.19-7.7h-2.11l6.4,9.31v6.09h1.76V104l6.41-9.29ZM146,45.36l-7.19-10.54v-.65c2.4-1.25,6.6-4.44,6.6-9.14,0-4.35-3.4-8-12.4-8h-1v1.94h.28c6.15,0,8.3,3.6,8.3,7.4,0,4.84-3.45,7.24-6.45,7.24H132v2h2.08l6.2,9.14a34.7,34.7,0,0,1,3.1,5.5l.2.15h7.24l.2-1.6-.55-.05C149.16,48.56,148.16,48.46,146,45.36Zm-.24,49.12a7.8,7.8,0,0,0-7.88,8v0a7.86,7.86,0,1,0,15.71,0v0A7.72,7.72,0,0,0,145.77,94.48Zm6,8a6,6,0,0,1-6,6.31,6.12,6.12,0,0,1-6.07-6.36v0a6.06,6.06,0,0,1,6-6.32,6.12,6.12,0,0,1,6.07,6.36Zm14.43,7.65H168V94.74h-1.74Zm-44.8-91.9c3.68.71,4.07,1.2,4.1,3.34V47.81c0,5.1-2.6,9-5.3,9.44l.6,1.2c5.6,0,9.1-4.89,9.1-13.24V17h-8.5Z"/>
      </svg>
    </div>
    <nav>
      <ul className="page-links">
        <li>
          <Link to="/">Home page</Link>
        </li>
        <li>
          <Link to="/about-us">About us</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact us</Link>
        </li>
      </ul>      
      <ul className="we-are-social">
        <li>
          <a href="https://www.facebook.com/ruddjoinery" rel="external" className="facebook">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.87 217.34">
              <path d="M73.26,217.34V118.2h33.28l5-38.64H73.26V54.9c0-11.19,3.11-18.81,19.15-18.81h20.46V1.52A274.19,274.19,0,0,0,83.05,0C53.56,0,33.36,18,33.36,51.07V79.56H0V118.2H33.36v99.14h39.9"/>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/rudd-joinery-limited/" rel="external" className="linkedin">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.04 24">
              <path d="M2.89,0A2.89,2.89,0,1,1,0,2.89,2.89,2.89,0,0,1,2.89,0ZM.4,8h5V24H.4Z"/>
              <path d="M8.5,8h4.78v2.19h.07a5.24,5.24,0,0,1,4.71-2.59c5.05,0,6,3.32,6,7.64V24h-5V16.2c0-1.86,0-4.25-2.59-4.25s-3,2-3,4.12V24h-5V8"/>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/ruddjoineryltd" rel="external" className="twitter">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.53 24">
              <path d="M29.53,2.84a12.2,12.2,0,0,1-3.48,1A6.12,6.12,0,0,0,28.72.44a12,12,0,0,1-3.85,1.47A6.06,6.06,0,0,0,14.39,6.06a6.28,6.28,0,0,0,.15,1.38A17.19,17.19,0,0,1,2.06,1.11a5.92,5.92,0,0,0-.82,3.05,6.07,6.07,0,0,0,2.69,5,6,6,0,0,1-2.74-.76v.08a6.06,6.06,0,0,0,4.86,5.94,6.05,6.05,0,0,1-1.6.21,6.12,6.12,0,0,1-1.14-.11A6.07,6.07,0,0,0,9,18.77a12.18,12.18,0,0,1-7.52,2.59A13.79,13.79,0,0,1,0,21.28,17.15,17.15,0,0,0,9.29,24c11.14,0,17.24-9.23,17.24-17.24,0-.26,0-.52,0-.78a12.35,12.35,0,0,0,3-3.14"/>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
    <div className="bottom-footer">
      <p className="wrapper">
        <span>
          &copy;{new Date().getFullYear()} Rudd Joinery Ltd
          </span>
        <span>
          <Link to="/privacy-policy">Privacy &amp; Cookies</Link>
        </span>
        <span>
          <Link to="/terms">Terms &amp; Conditions</Link>
        </span>
        <span>
          Website by <a href="http://www.neilrudd.co.uk" rel="author">Neil Rudd</a>
        </span>
      </p>
    </div>
  </footer>
)

export default Footer

