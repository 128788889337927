import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Navigation from './navigation'
import Footer from './footer'

const Layout = class extends Component {
  componentDidMount = () => {
    // Reset our product menu
    document.querySelector('body').state = false

    // Initialise SAL
    window.sal({
      threshold: 0.25,
    });
  }

  DesktopReset = (event) => {
    event.target.removeEventListener('transitionend', this.DesktopReset)

    event.target.removeAttribute('style')    
    //event.removeAttribute('style')
  }

  DesktopClose = (body, act, main) => {
    const A = []

    window.removeEventListener('resize', this.DesktopClose)

    if (body.state) {
      body.state = false

      main.addEventListener('transitionend', this.DesktopReset)
      //setTimeout(() => { this.DesktopReset(main) }, 500)
      main.style.top = 0 //marginTop

      A.forEach.call(act, element => {
        element.classList.remove('active')
      })
      // act.forEach(element => {
      //   element.classList.remove('active')
      // })
    }
  }

  DesktopOpen = (body, nav, act, main) => {
    const A = []

    if (!body.state) {
      body.state = true
      body.stateType = 1

      main.style.top = `${nav.clientHeight}px` //marginTop
      A.forEach.call(act, element => {
        element.classList.add('active')
      })
      // act.forEach(element => {
      //   element.classList.add('active')
      // })      
      window.addEventListener('resize', function reset(e) {
        e.target.removeEventListener(e.type, reset)

        body.state = false
        main.removeAttribute('style')

        A.forEach.call(act, element => {
          element.classList.remove('active')
        })
        // act.forEach(element => {
        //   element.classList.remove('active')
        // })
      })
    }
  }

  MobileReset = (event) => {
    event.target.removeEventListener('transitionend', this.MobileReset)

    event.target.removeAttribute('style')
    event.target.parentNode.removeAttribute('style')    
    //event.removeAttribute('style')
    //event.parentNode.removeAttribute('style')    
  }

  MobileClose = (body, nav, act) => {    
    window.removeEventListener('resize', this.MobileClose)

    if (body.state) {
      body.state = false

      nav.addEventListener('transitionend', this.MobileReset)
      //setTimeout(() => { this.MobileReset(nav) }, 500)
      nav.classList.remove('__visible')
        
      act.forEach(element => {
        element.classList.remove('active')
      });
    }
  }

  MobileOpen = (body, nav, act) => {   
    if (!body.state) {
      body.state = true
      body.stateType = 2
      
      nav.classList.add('__visible')
      nav.parentNode.style.height = `${body.clientHeight - 80}px`
      nav.style.height = `${body.clientHeight - 80}px`      
      
      act.forEach(element => {
        element.classList.add('active')
      })

      window.addEventListener('resize', function reset(e) {        
        e.target.removeEventListener(e.type, reset)
        
        body.state = false

        nav.classList.remove('__visible')
        nav.removeAttribute('style')
        nav.parentNode.removeAttribute('style')    
          
        act.forEach(element => {
          element.classList.remove('active')
        });   
      })
    }
  }

  ToggleMenu = (event) => {
    event.preventDefault()

    const 
        mainNavigation = document.querySelector('#main-navigation')
      , mainContainer = document.querySelector('main')
      , bodyContainer = document.querySelector('body')
      , actuators = document.querySelectorAll('.__actuator')

    if (window.matchMedia("(max-width: 1279px)").matches) {
      if (bodyContainer.state)
        this.MobileClose(bodyContainer, mainNavigation, actuators)
      else
        this.MobileOpen(bodyContainer, mainNavigation, actuators)
    } else {
      if (bodyContainer.state)
        this.DesktopClose(bodyContainer, actuators, mainContainer)
      else
        this.DesktopOpen(bodyContainer, mainNavigation, actuators, mainContainer)
    }
  }

  BrowserSupport = () => {
    
  }

  render() {
    const { children } = this.props
    let browserSupport = ''

    // if (!this.BrowserSupport()) {
    //   browserSupport = (
    //     <h1 style={{position:'absolute', zIndex:8000, top:0, left:0, border:'1px solid red'}}>UPGRADE YOUR BROWSER</h1>
    //   )
    // }

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}

        render={data => (
          <>
            <Helmet title={data.site.siteMetadata.title} >
              <html lang="en" />
            </Helmet>
            <Header toggleMenu={this.ToggleMenu} />
            <Navigation toggleMenu={this.ToggleMenu} />
            { browserSupport }
            <main>
              {children}
            </main>
            <Footer />                    
          </>
        )}
      />
    )
  } 
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
