import React, { Component } from 'react'
import Link from 'gatsby-link'

const Navigation = class extends Component {
  constructor(props) {
    super(props)

    this.SummaryOverHandler = this.SummaryOverHandler.bind(this)
    this.SummaryLeaveHandler = this.SummaryLeaveHandler.bind(this)
  }

  componentDidMount = () => {
    const
       location = window.location.pathname
     , activeLink = document.querySelector(`a[href='${location}']`)
     , summary = document.querySelector('.__jssummary')

     if (activeLink) {
      const attrValue = activeLink.getAttribute('data-desc')
            
      if (attrValue) {        
        const regex = /\*(\w.+)\*/gi
        const newElem = document.createElement('p')
      
        newElem.innerHTML = attrValue.replace(regex, '<strong>$1</strong>')
        
        summary.appendChild(newElem)
      }
     }
  }

  SummaryOverHandler = (event) => {    
    const 
        target = event.target
      , summary = document.querySelector('.__jssummary')
    
    if (target) {
      const attrValue = target.getAttribute('data-desc')
            
      if (attrValue) {        
        const regex = /\*(\w.+)\*/gi
        const newElem = document.createElement('p')
      
        newElem.innerHTML = attrValue.replace(regex, '<strong>$1</strong>')
        
        if (summary.childNodes.length > 0) {
          summary.firstChild.remove()
        }

        summary.appendChild(newElem)
      }
    }    
  }

  SummaryLeaveHandler = (event) => {
    const 
       location = window.location.pathname
     , activeLink = document.querySelector(`a[href='${location}']`)
     , summary = document.querySelector('.__jssummary')

     if (summary.childNodes.length > 0) {
      summary.firstChild.remove()
    }

    if (activeLink) {
      const attrValue = activeLink.getAttribute('data-desc')
            
      if (attrValue) {        
        const regex = /\*(\w.+)\*/gi
        const newElem = document.createElement('p')
      
        newElem.innerHTML = attrValue.replace(regex, '<strong>$1</strong>')
        
        summary.appendChild(newElem)
      }
     }
  }

  render() {
    return (
      <div id="main-navigation-wrapper">
        <nav id="main-navigation">
          <div className="wrapper">
            <div className="summary __jssummary">
              
            </div>
            <div className="nav">
              <ul className="pages">
                <li>
                  <h3>Main menu</h3>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About us</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>                      
                      <Link to="/contact-us">Contact us</Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="products">
                <li>
                  <h3>Windows</h3>
                  <ul>
                    <li>
                      <Link to="/windows/performance-storm" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="Our *Performance Storm* windows are manufactured using the very best quality engineered timber and hardware to provide an excellent combination of thermal performance and secure design incorporating dual weatherseals to further prevent weather ingress in exposed locations.">Performance storm</Link>
                    </li>
                    <li>
                      <Link to="/windows/traditional-flush" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="Our *Traditional Flush Casement* windows are designed with the replacement window market in mind. Manufactured using the very best quality engineered timber and quality hardware with a choice of Stays and Fasteners for the traditional cottage feel.">Traditional flush</Link>
                    </li>
                    <li>
                      <Link to="/windows/performance-flush" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="Our *High Performance Timber Flush Casement* windows are manufactured using the very best quality engineered timber and hardware to provide an excellent combination of thermal performance and secure design incorporating dual weatherseals to further prevent weather ingress in exposed locations.">Performance flush</Link>
                    </li>
                    <li>
                      <Link to="/windows/sliding-sash" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="The *Sliding Sash* windows we manufacture today combine an elegant and timeless look of that by gone era with the modern manufacturing techniques of today including dual weather seals, sustainable timber including Accoya® and a choice of stiles and finishes.">Sliding sash</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Doors</h3>
                  <ul>
                    <li>
                      <Link to="/doors/entrance" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="We design and manufacture beautiful *High Performance* doorsets. We’re passionate about helping homeowners enjoy premium bespoke products at affordable prices, so you’ll enjoy all the features and benefits of a modern entrance door, including performance weather seals, low level thresholds and performance butt hinges">Entrance</Link>
                    </li>
                    <li>
                      <Link to="/doors/french" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="Our hand-crafted *French* doors combine the elegance inherent in a French door with the performance features of today whether you decide to have full height glass or one of our panel and glass variations, these doors can really make a feature of your property to give a practical entry onto your garden or patio.">French</Link>
                    </li>
                    <li>
                      <Link to="/doors/bifold" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="Our bespoke *Bifold* doorsets can help you make a style statement and create sociable spaces. The door stacks neatly and compactly to the side, creating an open-plan area as well as the option to divide spaces, such as between a house and a conservatory during the evenings.">Bifold</Link>
                    </li>
                    {/* <li>
                      <Link to="/doors/sliding" activeClassName="active" onMouseOver={this.SummaryHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="">Sliding</Link>
                    </li> */}
                    <li>
                      <Link to="/doors/bead-and-butt" activeClassName="active" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="A popular choice for period properties as well as refurbishment projects and new builds, our Norfolk *Bead and Butt* doors are a traditional style of door that can add a timeless touch to a property.">Bead &amp; butt</Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <h3>Other</h3>
                  <ul>
                    <li>
                      <Link to="/staircases.js" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="Our stunning bespoke *Staircases* can be the difference between a house and your dream home. Long considered the focal point to any home, getting your staircase right is hugely important to style of a property and says so much not only about the house itself, but the people who live in it.">Staircases</Link>
                    </li>
                    <li>
                      <Link to="/gates.js" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="we design and manufacture *Gates* to meet customers expectations and requirements.">Gates</Link>
                    </li>
                    <li>
                      <Link to="/specialist.js" onMouseOver={this.SummaryOverHandler} onMouseLeave={this.SummaryLeaveHandler} data-desc="As well as our range of bespoke performance windows and doors we also provide a bespoke service allowing us to design and manufacture products to meet your exact requirements.">Specialist</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
            <button className="ci __nav-close" onClick={this.props.toggleMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.414 23.414">
                <g transform="translate(-5886.793 -96.793)">
                  <line y1="22" x2="22" transform="translate(5887.5 97.5)"/>
                  <line x2="22" y2="22" transform="translate(5887.5 97.5)"/>
                </g>
              </svg>
            </button>
          </div>
        </nav>
      </div>
    )
  }
}

export default Navigation